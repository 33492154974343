import React from "react";

import SiteStructure from "../components/siteStructure/SiteStructure";
import { nosotros } from "../images/index";
import SEO from "../components/SEO/Seo";
import "./about.scss";

export default function About() {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location =
  //     "http://www.blueskysa.com.ar/movile/01-empresa-bluesky.html";
  // }
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Nosotros"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio ,Nosotros, Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  - Botellas de vidrio Blue Sky SA - Botellas de vidrio . Espumantes. Champagne, champañeras o cava. Alta, baja, con picada, sin picada. 750 ml. Color verde, ambar, marrón, blanco y verde oscuro. Boca corcho y corona. Mendoza - Argentina."
        description=" Blue Sky es una empresa que se especializa en la
        comercialización de envases de vidrio. Nuestra inigualable
        flexibilidad , nos permitio ser parte de exitosos proyectos en
        distintos segmentos de la Industria de Alimentos (Vinos,
        Espumantes, Licores, Cervezas, Gaseosas, Aguas, Jugos,
        Aceites, etc.), Actualmente abastecemos a Clientes Nacionales,
        Grandes y Pequeños, con nuestra filosofía de crear y mantener
        una cálida y estrecha relación con todos nuestros Clientes."
      />
      <div className="my-brackground">
        <SiteStructure>
          <div>
            <h1 className="text-white d-flex flex-row-reverse">BLUE SKY</h1>
            <div className="divider  mb-3"></div>
            <div className="">
              <div className="p-6 text-white mb-3">
                <h3 className="text-nosotros">
                  Blue Sky es una empresa que se especializa en la
                  comercialización de envases de vidrio. Nuestra inigualable
                  flexibilidad , nos permitio ser parte de exitosos proyectos en
                  distintos segmentos de la Industria de Alimentos (Vinos,
                  Espumantes, Licores, Cervezas, Gaseosas, Aguas, Jugos,
                  Aceites, etc.), Actualmente abastecemos a Clientes Nacionales,
                  Grandes y Pequeños, con nuestra filosofía de crear y mantener
                  una cálida y estrecha relación con todos nuestros Clientes.
                </h3>
              </div>
              <div className="p-6">
                <img
                  src={nosotros}
                  alt="Nosotros BlueSky"
                  className=" imagen-nosotros"
                />
              </div>
            </div>
          </div>
        </SiteStructure>
      </div>
    </>
  );
}
